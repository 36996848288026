@import url(https://fonts.googleapis.com/css?family=Libre+Baskerville|Shadows+Into+Light|Uncial+Antiqua&display=swap);
@import url(https://fonts.googleapis.com/css?family=Libre+Baskerville|Shadows+Into+Light|Uncial+Antiqua&display=swap);
@import url(https://fonts.googleapis.com/css?family=Libre+Baskerville|Shadows+Into+Light|Uncial+Antiqua&display=swap);
@import url(https://fonts.googleapis.com/css?family=Libre+Baskerville|Shadows+Into+Light|Uncial+Antiqua&display=swap);
@import url(https://fonts.googleapis.com/css?family=Libre+Baskerville|Shadows+Into+Light|Uncial+Antiqua&display=swap);
body{margin:0;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;background-color:lightslategray}code{font-family:source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace}

.App{text-align:center}.App-logo{height:40vmin;pointer-events:none}@media (prefers-reduced-motion: no-preference){.App-logo{-webkit-animation:App-logo-spin infinite 20s linear;animation:App-logo-spin infinite 20s linear}}.App-header{background-color:#282c34;min-height:100vh;display:flex;flex-direction:column;align-items:center;justify-content:center;font-size:calc(10px + 2vmin);color:white}.App-link{color:#61dafb}@-webkit-keyframes App-logo-spin{from{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}@keyframes App-logo-spin{from{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}.btn-warning{background-color:#fff8dc}nav{font-family:"Libre Baskerville",serif}nav .navbar{position:relative;display:flex;flex-wrap:wrap;align-items:center;justify-content:center !important;padding:.5rem 1rem}nav li{list-style:none}nav .nav-link{font-size:2rem;display:inline-block;justify-content:center}nav #logoutBtn{color:#0f0f0a}nav #saveBtn{color:#0f0f0a}nav .navbar-brand{color:#0f0f0a;font-family:"Libre Baskerville",serif;font-size:2rem}

body{margin:0;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;background-color:lightslategray}code{font-family:source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace}.wordCard{display:flex;justify-content:center;margin:10px}.wordCard .card{border-radius:0px;max-width:500px;justify-content:center;position:relative;text-align:center;padding:2px;background-color:#fff8dc}.wordCard .card .card-img-top{z-index:0;border-radius:0px}.wordCard .card .tattooScript{z-index:1;color:#0f0f0a;text-transform:uppercase;font-weight:750;max-width:-webkit-fit-content;max-width:-moz-fit-content;max-width:fit-content;flex-shrink:1}.wordCard .card .font1{font-family:"Libre Baskerville",serif;font-weight:bold}.wordCard .card .font2{font-family:"Uncial Antiqua",cursive}.wordCard .card .font3{font-family:"Shadows Into Light",cursive;color:#141f1f;font-weight:bold}.wordCard .card #halfOne{position:absolute;top:33%;right:55%;text-align:right !important;font-size:2.2rem;margin-right:0% !important}.wordCard .card #halfOne span{margin-right:0% !important;text-align:right}.wordCard .card #halfTwo{position:absolute;text-align:left !important;top:33%;left:56%;font-size:2.2rem;margin-left:0% !important}.wordCard .card #halfTwo span{margin-left:0% !important;text-align:left}.wordCard .card-footer .buttonHolder{justify-content:space-around}.wordCard .card-footer .buttonHolder .dropdown{width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;display:inline-block;vertical-align:middle}.wordCard .card-footer .buttonHolder .dropdown .dropdown-item{font-size:1.3rem;text-transform:uppercase;font-weight:750}.wordCard .card-footer .buttonHolder .dropdown img{max-width:200px;pointer-events:none}.wordCard .card-footer .buttonHolder .btn-dark{margin:5px}

body{margin:0;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;background-color:lightslategray}code{font-family:source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace}nav{font-family:"Libre Baskerville",serif}nav .navbar{position:relative;display:flex;flex-wrap:wrap;align-items:center;justify-content:center !important;padding:.5rem 1rem}nav li{list-style:none}nav .nav-link{font-size:2rem;display:inline-block;justify-content:center}nav #logoutBtn{color:#0f0f0a}nav #saveBtn{color:#0f0f0a}nav .navbar-brand{color:#0f0f0a;font-family:"Libre Baskerville",serif;font-size:2rem}

body{margin:0;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;background-color:lightslategray}code{font-family:source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace}h1{font-family:"Libre Baskerville",serif}.tattoos{justify-content:center}nav{font-family:"Libre Baskerville",serif;justify-content:center !important}nav .navbar{position:relative;display:flex;flex-wrap:wrap;align-items:center;padding:.5rem 1rem}nav li{list-style:none}nav .nav-link{font-size:2rem;display:inline-block;justify-content:center}nav #logoutBtn{color:#0f0f0a}nav #saveBtn{color:#0f0f0a}nav .navbar-brand{color:#0f0f0a;font-family:"Libre Baskerville",serif;font-size:2rem}

